.footer{

    display: flex;
    gap: 1rem;
}
.input-field{

    background-color: aqua;
    border-radius: 20px;
    border-color: var(--orange);
    width: fit-content;
    font-size: smaller;
}
button{

    border-radius: 20px;
}
.email>div{
    display: flex;
justify-content: center;
justify-items: center;
align-items: center;
align-content: center;
    background-color:var(--orange);
    position: absolute;
    width: 4rem;
    height: 4.5%;
    left: 6.7rem;
    font-size: small;
    margin-top: 0.2rem;
    border-radius: 16px;
    margin-left: 0.1rem;
    font-weight: bolder;

}
.email>div:hover{

    cursor: pointer;
    transform: scale(1.0);
    background-color: red;
}
input{

    background-color: var(--orange);
    border-radius: 18px;
}
input:hover{

    cursor: pointer;
    background-color: transparent;
}
::placeholder{
display: flex;
text-align: center;
    color: white;
}
.icons{

    display: flex;
    gap: 3rem;
    justify-content: flex-end;
    
    
}
.icons>img{

    height: 20px;
    position: relative;
    right: 1rem;
    bottom: 1.6rem;
}
.icons>img:nth-child(3){
position: relative;
bottom: 1.9rem;
    height: 40px;
}
.icons>img:hover{

    cursor: pointer;
  
    background-size: 2px;
    background: var(--orange);
    border-radius: 20px;
    transform: scale(1.1);
}.icons>a>img:nth-child(1){
position: relative;
    height:20px ;
    bottom: 1.6rem;
    right: 1.5rem;
}
.icons>a>img:nth-child(1):hover{
   cursor: pointer;
   background-color: var(--orange);
   
   transform: scale(1.1);
    }
    .icons>a>img:nth-child(3){
        position: relative;
            height:30px ;
            bottom: 1.6rem;
            
        }