.grid
{
    margin-top: 2rem;
    gap: 2rem;
display: flex;

}
.left-grid{
    
    display: grid;

    gap: 1rem;



    
}
.right{

    
}
.left-grid>img{

    
}
.left-grid>img:nth-child(1){
object-fit: cover;
object-position: center;
background-size: cover;
    height: 18rem;
    width: 10rem;
    grid-row: 1/3;
}
.left-grid>img:nth-child(2){
width: 18rem;
    height: 10rem;
    grid-column: 2/4;
    
    
}
.left-grid>img:nth-child(3){
grid-row: 2;
 
    width: 10rem;
    
}
.left-grid>img:nth-child(4){

    width: 7rem;
    grid-row: 2;
    
}
.text>span:nth-child(1){
-webkit-text-stroke-color: black;
    font-size: 2rem;
}

.text>span:nth-child(2){
font-weight: bolder;
text-transform: uppercase;
color: var(--orange);
}
.reasons{

    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-content: center;
    
}
.text{

    display: flex;
    flex-direction: column;
    
    
}
.reasons>div>img{

    height:16px;
    position: relative;
    right:11rem;
    top: 2.2rem;
    
}
.reasons>div>span{

font-size: small;
font-weight: bold;
position: relative;
right:10rem;
text-transform: uppercase;
top: 2rem;
    
}
.partner>div>img{
height: 14px;
gap: 1rem;
    
}
.partner>div{
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    right:10rem;
    top: 0.6rem;
    position: relative;
        
    }
    .partner>span{

        color: var(--orange);
    }