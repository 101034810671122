:root{

    --orange:#f48915;
}

.style-text{
letter-spacing: 2px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color:wheat;
    color: transparent;
}

body::-webkit-scrollbar{

    display: none;
}